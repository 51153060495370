import React from "react";
import "./GenericButton.css";

/*
    This GenericButton Class is used for any buttons needed for Lesson Quiz section of the website. The following props are needed: title, label
*/

const GenericButton = ({title, label, type, onClick}) => {
    return(
        <div>
            <button className="genericButton" aria-label={label} tabIndex={0} onClick={onClick} type={type}>
                {title}
            </button>
        </div>
    );
};

export default GenericButton;