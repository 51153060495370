import "./App.css";
import React, { useState, useEffect } from "react";

import "./styles.scss";

import { Route, Routes, useLocation} from "react-router-dom";
import MainHeader from "./components/MainHeader/MainHeader";
import About from "./components/About/About";
import Welcome from "./components/Welcome/Welcome";
import Lessons from "./components/Lessons/Lessons";
import LessonHOC from "./components/Lessons/LessonHOC";
import Footer from "./components/Footer/Footer";
import CookieConsentMessage from "./components/CookieConsent/CookieConsentMessage";

const App = () => {
  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false);
  const [showResult, setshowResult] = useState(false);
  const [darkTheme, setDarkTheme] = useState(0);
  const [fontSize, setFontSize] = React.useState(16);
  const location = useLocation();

  useEffect(() => {
      window.gtag('event', 'page_view', {
          page_path: location.pathname + location.search,
          page_location: window.location.href
      })
  }, [location]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 820) {
        setShowMobileNavMenu(false);
        setshowResult(true);
      } else {
        setShowMobileNavMenu(true);
        setshowResult(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div className={Number(darkTheme) === 1 ? "app-dark" : "app-light"}>
      <MainHeader
        showMobileNavMenu={showMobileNavMenu}
        darkTheme={darkTheme}
        fontSize={fontSize}
        onToggleClick={() => {
          setDarkTheme((darkTheme) => !darkTheme);
        }}
        onFontIncreaseClick={() => {
          setFontSize((fontSize) => fontSize + 1);
        }}
        onFontDecreaseClick={() => {
          setFontSize((fontSize) => fontSize - 1);
        }}
        onFontClick={() => {
          setFontSize((fontSize) => 16);
        }}
      />

      <div
        style={{
          fontSize: `${fontSize}px`,
        }}
      >
        <main
          className={Number(darkTheme) === 1 ? "main-dark" : "main-light"}
          tabIndex={0}
        >
          <Routes>
            <Route exact path="/" element={<Welcome />}/>
            <Route path="lessons" element={<Lessons showResult={showResult} />}/>
            <Route path="lessons/:lessonId" element={<LessonHOC/>} />
            <Route path="about" element={<About />} />
          </Routes>
        </main>
      </div>
      <Footer />
      <CookieConsentMessage />
    </div>
  );
};

export default App;
