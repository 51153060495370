import React from "react";

import classes from "./Footer.module.css";
import aph_logo from "../../assets/images/aph-logo-white.png";

const Footer = () => {
  return (
    <div className={classes["footer"]}>
      <div className={classes["container"]}>
        <a href="https://www.aph.org/">
          <img src={aph_logo} alt="aph logo" className={classes["img"]} />
        </a>

        <div>
          <a
            rel="noopener"
            href="https://www.aph.org/"
            className={classes["text"]}
          >
            American Printing House for the Blind
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
