import React from "react";
import CookieConsent from "react-cookie-consent";
import classes from "./CookieConsentMessage.module.css";

const CookieConsentMessage = () => (
  <CookieConsent
    location="bottom"
    buttonText="Got it!"
    cookieName="TyperCookieConsent"
    style={{ backgroundColor:"#000000", }}
    buttonStyle={{
      backgroundColor: "#5CA0A7",
      color: "#FFFFFF",
      fontSize: "13px",
      fontWeight: "bold",
      borderRadius: "3px",
    }}
    expires={365}
  >
    <div tabIndex="0" className={classes["text-style"]}>
      This website uses cookies to ensure you get the best experience using
      Typer Online.{" "}
    </div>

    <div className={classes["text-style"]}>
      <a
        href="https://www.aph.org/privacy-policy/"
        className={classes["hyperlinkStyle"]}
        target="_blank"
        rel="noreferrer"
      >
        Learn more about our cookie policy.
      </a>
    </div>
  </CookieConsent>
);

export default CookieConsentMessage;

