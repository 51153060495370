import React from "react";
import classes from "./Buttons.module.css";
import { CgSun } from "react-icons/cg";
import { HiMoon } from "react-icons/hi";

const Buttons = (props) => {
  const icon =
    Number(props.darkTheme) === 0 ? <HiMoon size={30} /> : <CgSun size={30} />;

  return (
    <div className={classes["container"]}>
      <div className={classes["column"]}>
        <button
          className={classes["toggle"]}
          onClick={props.onToggleClick}
          aria-label="select theme"
        >
          {icon}
        </button>
      </div>
      <div className={classes["column"]}>
        <button
          className={classes["font-toggle"]}
          onClick={props.onFontIncreaseClick}
          aria-label="increase font"
        >
          {"A+"}
        </button>
      </div>
      <div className={classes["column"]}>
        <button
          className={classes["font-toggle"]}
          onClick={props.onFontClick}
          aria-label="default font"
        >
          {"A"}
        </button>
      </div>
      <div className={classes["column"]}>
        <button
          className={classes["font-toggle"]}
          onClick={props.onFontDecreaseClick}
          aria-label="decrease font"
        >
          {"A-"}
        </button>
      </div>
    </div>
  );
};

export default Buttons;
