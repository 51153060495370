import React from 'react';
import { useParams } from 'react-router-dom';
import { useSpeechSynthesis } from 'react-speech-kit';
import LessonManager from './LessonManager';

const LessonHOC = (props) => {
    const params = useParams();
    const speechSynthesis = useSpeechSynthesis();

    return(
        <LessonManager
            {...props}
            params={params}
            speechSynthesis={speechSynthesis}
        />
    );
}

export default LessonHOC;
