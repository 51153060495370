import React, { Component } from 'react';
import GenericButton from '../Buttons/GenericButton';
import { isOnAndroidDevice } from '../GlobalFuncs';
import strings from '../strings';
import './LessonIntro.css';

/*This Component is responsible for showing the screen when the user first presses a lesson link or repeats/progresses to the next lesson. Check LessonManager.js for usage */
/*Props Passed In:
    - onClickSelfVoice: (function)
    - onSelfVoiceChange: (function)
    - onStartLesson: (function)
    - isSelfVoicing: (boolean)
    - LessonInstruction: (string) */

class LessonIntro extends Component{
    constructor(props){
        super(props);

        this.state = {
            lessonInstruction: !isOnAndroidDevice()? this.props.LessonInstruction: this.props.LessonInstruction + strings.ANDROID_DISCLAIMER,
            selfVoicingAccessibilityLabel: this.props.isSelfVoicing? strings.SELF_VOICING_TOGGLE_ACCESSIBILITY_LABEL + strings.STATUS_ON: strings.SELF_VOICING_TOGGLE_ACCESSIBILITY_LABEL + strings.STATUS_OFF
        };
    }


    onClickSelfVoiceSwitch = () => {
        let switchElement = document.getElementById('selfVoiceSwitch');
        let checked = switchElement.checked;


        this.props.onClickSelfVoice(checked);
    };

    render(){
        return(
            <div>
                <h3 tabIndex={0}>{this.state.lessonInstruction}</h3>
                <GenericButton title={strings.START_BTN_TITLE} label={strings.START_BTN_ACCESSIBILITY_LABEL} onClick={this.props.onStartLesson}/>
                <label> {strings.SELF_VOICING_TOGGLE_LABEL}
                    <label className='switch' id='switchBox'>
                        <input id='selfVoiceSwitch' type='checkbox' role='switch' checked={this.props.isSelfVoicing} aria-label={this.state.selfVoicingAccessibilityLabel} onClick={this.props.onClickSelfVoice} onChange={this.props.onSelfVoiceChange}/>
                        <span className="slider"></span>
                    </label>
                </label>
            </div>
        );
    };
}


export default LessonIntro;