export const lessonsList = [
  {
    id: 1,
    title: "Home keys for the left hand",
    speed: 25,
    level: 100,
    accuracy: 85,
    instructions:
      "Beginning with your little finger, place the fingers of your left hand on A,S,D, and F. Use your thumb to press the space bar after each word or set of letters. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
     {
       text: "asdf asdf",
       type: "letter",
     },
     {
      text: "fdsa fdsa",
      type: "letter",
     },
     {
      text: "adsf adsf",
      type: "letter",
     },
     {
      text: "asdf fdsa",
      type: "letter",
     },
     {
       text: "a as a as a as",
       type: "word"
     },
     {
       text: "fad add fad add fad",
       type: "word",
     },
     {
       text: "a as fad add",
       type: "word"
     },
     {
       text: "a sad fad",
       type: "word"
     },
     {
       text: "add a sad fad sad",
       type: "word"
     },
     {
       text: "add a sad fad dad",
       type: "word"
     }
    ],
    dictations: [],
    enabled: true,
  },
  {
    id: 2,
    title: "Home keys for the right hand",
    speed: 25,
    level: 110,
    accuracy: 85,
    instructions:
      "Beginning with your index finger, place the fingers of your right hand on J, K, L, and semicolon. Use your right thumb to strike the space bar after each word or each set of letters. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "jkl; jkl;",
        type: "letter"
      },
      {
        text: ";lkj ;lkj",
        type: "letter"
      },
      {
        text: "jl;k k;jl",
        type: "letter"
      },
      {
        text: "asdf jkl;",
        type: "letter"
      },
      {
        text: "ad as add fad sad dad",
        type: "word"
      },
      {
        text: "ask salad ask salad",
        type: "word"
      },
      {
        text: "lad flask all",
        type: "word"
      },
      {
        text: "as sad lass",
        type: "word"
      },
      {
        text: "sad ad sad fad",
        type: "word"
      },
      {
        text: "ask dad",
        type: "word"
      },
      {
        text: "as flask falls",
        type: "word"
      }
    ],
    dictations: [],
    enabled: true,
  },
  {
    id: 3,
    title: "New keys, G and H",
    speed: 25,
    level: 120,
    accuracy: 85,
    instructions:
      "Use your left index finger to strike G.  Use your right index finger to strike H. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdf jkl;",
        type: "letter"
      },
      {
        text: "fg gf jh hj fgjh",
        type: "letter"
      },
      {
        text: "asdfg hjkl; asdfg hjkl;",
        type: "letter"
      },
      {
        text: "gh fghj jhgf asdfg hjkl;",
        type: "letter"
      },
      {
        text: "as lads fall ask gall",
        type: "word"
      },
      {
        text: "flag sag jag lag",
        type: "word"
      },
      {
        text: "dash gash lash flash ash",
        type: "word"
      },
      {
        text: "glad gas has half hall glass",
        type: "word"
      },
      {
        text: "a sag flag falls",
        type: "word"
      },
      {
        text: "dad has half a glass",
        type: "word"
      },
      {
        text: "dad had a lad",
        type: "word"
      },
      {
        text: "a flask falls",
        type: "word"
      },
    ],
    dictations: [],
    enabled: true,
  },
  {
    id: 4,
    title: "Capitals and the Period Key",
    speed: 25,
    level: 180,
    accuracy: 85,
    instructions:
      "Use two hands to type capital letters.  Use right shift when the letter you want to type is under your left hand.  Strike it with your right little finger.  Use left shift when the letter you want is under your right hand.  Strike it with your left little finger.  Use your right ring finger to strike the period. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "Aa Ss Dd",
        type: "letter"
      },
      {
        text: "l. .l L.L l.l l",
        type: "letter"
      },
      {
        text: "a. s. d. f. g. h. j. k. l. ;. f. j.",
        type: "letter"
      },
      {
        text: "Dad. Flag. Glass.",
        type: "word"
      },
      {
        text: "A lad had a dad.",
        type: "phrase"
      },
      {
        text: "Dad had a salad.",
        type: "phrase"
      },
      {
        text: "A lad has had a fall.",
        type: "phrase"
      },
      {
        text: "Dad had a flag.",
        type: "phrase"
      },
      {
        text: "A lad had a flask.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },
  {
    id: 5,
    title: "The Caps Lock Key",
    speed: 25,
    level: 190,
    accuracy: 85,
    instructions:
      "Caps lock is located directly to the left of A. Use caps lock when you need to type several capital letters continuously.  To use the caps lock feature, press caps lock once with your left little finger before typing the letters you wish to capitalize.  Remember, pressing caps lock again will turn off the caps lock feature. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdf ASDF asdf ASDF",
        type: "letter"
      },
      {
        text: ";lkj ;LKJ k;jl",
        type: "letter"
      },
      {
        text: "fdsa ADSF fsda SFAD dasf",
        type: "letter"
      },
      {
        text: "HJKL; hjkl; SDFG",
        type: "letter"
      },
      {
        text: "SAFDG jkl;",
        type: "letter"
      },
      {
        text: "FSDA asdf AFGSD",
        type: "letter"
      },
      {
        text: "FALL hall jag FAD ask LASH",
        type: "word"
      },
      {
        text: "SAD lad HAD gaff dash GAS",
        type: "word"
      },
      {
        text: "A LAD had a FLASK.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },
  {
    id: 6,
    title: "New Keys are T and U",
    speed: 25,
    level: 210,
    accuracy: 85,
    instructions:
      "The T is up and right of F. The U is almost directly over J.  Use your left index finger to strike T.  Use your right index finger to strike U. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "ft ft ftju",
        type: "letter"
      },
      {
        text: "at st dt ft gt hu ju ku lu ;u tu",
        type: "letter"
      },
      {
        text: "hat fat last task",
        type: "word"
      },
      {
        text: "hull dull gull lull gush dust just",
        type: "word"
      },
      {
        text: "Judd had a fall.",
        type: "phrase"
      },
      {
        text: "A tall lad had a fall.",
        type: "phrase"
      },
      {
        text: "That gull had us sad.",
        type: "phrase"
      },
      {
        text: "Tad sat at last.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },
  {
    id: 7,
    title: "New keys are E and I",
    speed: 25,
    level: 220,
    accuracy: 85,
    instructions:
      "The E is above D, and the I is above K.  Use your left middle finger to strike E.  Use your right middle finger to strike I. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "de ed ded ki ik kik deki",
        type: "letter"
      },
      {
        text: "ae se de fe ge te ui",
        type: "letter"
      },
      {
        text: "set set jet",
        type: "word"
      },
      {
        text: "tied died lied hide",
        type: "word"
      },
      {
        text: "She has a tall glass.",
        type: "phrase"
      },
      {
        text: "He eats seeds at sea.",
        type: "phrase"
      },
      {
        text: "The ladies liked the fig tea.",
        type: "phrase"
      },
      {
        text: "Judd had tea at the hut.",
        type: "phrase"
      },
      {
        text: "I ate a fish salad.",
        type: "phrase"
      },
      {
        text: "Jill tied it tight.",
        type: "phrase"
      },
      {
        text: "Jill likes the elk.",
        type: "phrase"
      },
      {
        text: "dad had a lad",
        type: "word"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },
  {
    id: 8,
    title: "New keys are R and N",
    speed: 25,
    level: 230,
    accuracy: 85,
    instructions:
      "The R is directly above the F, and the N is below and left of J. Use your left index finger to strike R.  Use your right index finger to strike N. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdfg hjkl; asdft ujkl; ftuj",
        type: "letter"
      },
      {
        text: "fr rf frf jn nj jnj frjn",
        type: "letter"
      },
      {
        text: "ar sr dr fr gr tr hn jn kn ln un in",
        type: "letter"
      },
      {
        text: "rag rid far run hen",
        type: "word"
      },
      {
        text: "land until friend jungle",
        type: "word"
      },
      {
        text: "nut nest rat sand ran star nine",
        type: "word"
      },
      {
        text: "The jungle is a hard land.",
        type: "phrase"
      },
      {
        text: "The girls are sailing in the sea.",
        type: "phrase"
      },
      {
        text: "The lights are at the hill.",
        type: "phrase"
      },
      {
        text: "The rug is hanging there.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 9,
    title: "New keys are V and O",
    speed: 25,
    level: 240,
    accuracy: 85,
    instructions:
      "The V is below and right of F, and O is directly above the L. Use your left index finger to strike V.  Use your right ring finger to strike O. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "hjkl; asdft ujkl; asder injkl",
        type: "letter"
      },
      {
        text: "fv fv fvf lo ol fvlo love",
        type: "letter"
      },
      {
        text: "av dv fv tv ev rv jo ko lo uo io no",
        type: "letter"
      },
      {
        text: "save van sold good",
        type: "word"
      },
      {
        text: "leave house loan",
        type: "word"
      },
      {
        text: "Rover is a good dog.",
        type: "phrase"
      },
      {
        text: "The violets are red.",
        type: "phrase"
      },
      {
        text: "The lilies are in the vase.",
        type: "phrase"
      },
      {
        text: "The house is near the hill.",
        type: "phrase"
      },
      {
        text: "Judd sold the van to our dad.",
        type: "phrase"
      },
      {
        text: "David loves to sing and run.",
        type: "phrase"
      },
      {
        text: "It is a good thing to have a goal.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 10,
    title: "New keys are C and Y",
    speed: 25,
    level: 250,
    accuracy: 85,
    instructions:
      "The C is below D, and Y is directly above the H. Use your left middle finger to strike C. Use your right index finger to strike Y. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdfg hjkl; svert",
        type: "letter"
      },
      {
        text: "dc cd dcd jy yj dcjy",
        type: "letter"
      },
      {
        text: "ac sc dc fc ec rc jy ky ly hy ny uy",
        type: "letter"
      },
      {
        text: "cold cut yes joy",
        type: "word"
      },
      {
        text: "yell creaky coy",
        type: "word"
      },
      {
        text: "The tiny tyke covered the ivy.",
        type: "phrase"
      },
      {
        text: "The curvy road is very icy.",
        type: "phrase"
      },
      {
        text: "The youngest cousin yelled at the duck.",
        type: "phrase"
      },
      {
        text: "The clouds looked very cold today.",
        type: "phrase"
      },
      {
        text: "The cat has a yoke on its neck.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 11,
    title: "New keys are W and M",
    speed: 25,
    level: 260,
    accuracy: 85,
    instructions:
      "The W is above S, and the M is below J.  Use your left ring finger to strike W.  Use your right index finger to strike M. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "hjkl; asert uiojk dfvnj",
        type: "letter"
      },
      {
        text: "sw ws sws jm jmj jmj",
        type: "letter"
      },
      {
        text: "aw sw dw fw ew rw km lm hm um ;m",
        type: "letter"
      },
      {
        text: "wave went mean make",
        type: "word"
      },
      {
        text: "make mark saw was",
        type: "word"
      },
      {
        text: "Don gave me a dime.",
        type: "phrase"
      },
      {
        text: "The mouse went into the hole.",
        type: "phrase"
      },
      {
        text: "It is time to go to work.",
        type: "phrase"
      },
      {
        text: "The woman will weave the thread.",
        type: "phrase"
      },
      {
        text: "That was a wild game.",
        type: "phrase"
      },
      {
        text: "It is a good thing to have a goal.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 12,
    title: "New keys are B and P",
    speed: 25,
    level: 270,
    accuracy: 85,
    instructions:
      "The B is directly to the right of V, and P is directly to the right of O. Use your left index finger to strike B.  Use your right little finger to strike P. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdfg hjkl; wsert yuioj bcvnm",
        type: "letter"
      },
      {
        text: "fbf fb bf ;p ;p; fb;p",
        type: "letter"
      },
      {
        text: "fbf lp; fbg ;pl fbf",
        type: "letter"
      },
      {
        text: "ball bath pat pan",
        type: "word"
      },
      {
        text: "pink bear paid bag",
        type: "word"
      },
      {
        text: "Please pass the pears to me.",
        type: "phrase"
      },
      {
        text: "Bob paid the bill and the publisher.",
        type: "phrase"
      },
      {
        text: "The pet bear played in the pen.",
        type: "phrase"
      },
      {
        text: "Put the blanket on the bed.",
        type: "phrase"
      },
      {
        text: "The big bull chased the people.",
        type: "phrase"
      },
      {
        text: "Bob plays bass in the school band.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 13,
    title: "New keys are X and Q",
    speed: 25,
    level: 280,
    accuracy: 85,
    instructions:
      "The X is directly to the left of C, and the Q is above A.  Use your left ring finger to strike X.  Use your left little finger to strike Q. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdfg hjkl; swert yuioj fcvnm",
        type: "letter"
      },
      {
        text: "sxs aqa sxs aqa",
        type: "letter"
      },
      {
        text: "jx kx fx lx sx aq nq qu ;q sxaq",
        type: "letter"
      },
      {
        text: "six wax quit aqua",
        type: "word"
      },
      {
        text: "question fox quarter",
        type: "word"
      },
      {
        text: "The queen had a quarrel with them.",
        type: "phrase"
      },
      {
        text: "The red fox chased the ox.",
        type: "phrase"
      },
      {
        text: "The man fixed the ax for Max.",
        type: "phrase"
      },
      {
        text: "We have time for a quick question.",
        type: "phrase"
      },
      {
        text: "The quarters are in the quart jar.",
        type: "phrase"
      },
      {
        text: "Please use the stairs to exit.",
        type: "phrase"
      },
      {
        text: "Quint plays a quirky xylophone in the band.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 14,
    title: "New keys are Z and comma",
    speed: 25,
    level: 300,
    accuracy: 85,
    instructions:
      "The Z is directly to the left of X, and the comma is directly right of M. Use your left little finger to strike Z.  Use your right middle finger to strike the comma. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdfg hjkl; qwert yuioj xcvnm fblp;",
        type: "letter"
      },
      {
        text: "az za aza k, k,k azk,",
        type: "letter"
      },
      {
        text: "zap, buzz zoom,",
        type: "word"
      },
      {
        text: "zip breeze jazz",
        type: "word"
      },
      {
        text: "Please pass a bun, meat, and tea.",
        type: "phrase"
      },
      {
        text: "We sell zinc, iron, and quartz.",
        type: "phrase"
      },
      {
        text: "The zip code is not on the letter.",
        type: "phrase"
      },
      {
        text: "John left, but I stayed.",
        type: "phrase"
      },
      {
        text: "There are six zebras at the zoo.",
        type: "phrase"
      },
      {
        text: "The pizza is very hot, stringy, and tasty.",
        type: "phrase"
      },
      {
        text: "Zoe plays a zany guitar in Zanzibar.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 15,
    title: "New keys are quotation mark and apostrophe",
    speed: 25,
    level: 330,
    accuracy: 85,
    instructions:
      "The key representing the quotation mark and the apostrophe is located directly to the right of the semi-colon. Use your right little finger to strike the apostrophe.  Use two hands to type a quotation mark.  Use your left little finger to reach left shift and your right little finger to reach the quotation mark. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdfg hjkl; qwert yuioj zxcvb nmop;",
        type: "letter"
      },
      {
        text: "'\"' ;'\" ;'\" ;'\" ;'\" asjk\" jkl\" 'asdf\"",
        type: "letter"
      },
      {
        text: ";'\" \"'; qwert\" 'jkl'\" a's\"d j'k\" as\"dl' '\"lkj",
        type: "letter"
      },
      {
        text: "don't can't won't she's didn't he's",
        type: "word"
      },
      {
        text: "John said, \"I will go away.\"",
        type: "phrase"
      },
      {
        text: "She can't leave until one o'clock.",
        type: "phrase"
      },
      {
        text: "\"Let's go play,\" Mary declared.",
        type: "phrase"
      },
      {
        text: "Mary said, \"I like horses.\"",
        type: "phrase"
      },
      {
        text: "He doesn't like to eat fish.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 16,
    title: "Number keys",
    speed: 25,
    level: 500,
    accuracy: 85,
    instructions:
      "Here are the fingers to use for striking the number keys:\n\nleft little finger: 1\nleft ring finger: 2\nleft middle finger: 3\nleft index finger: 4 and 5\nright index finger: 6 and 7\nright middle finger: 8\nright ring finger: 9\nright little finger: 0. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "a 1 a 1 aa 11 aa",
        type: "letter"
      },
      {
        text: "j7 j7 jj 77",
        type: "letter"
      },
      {
        text: "f4 f4 ff 44 ff",
        type: "letter"
      },
      {
        text: "k8 k8 88 k8k 88k",
        type: "letter"
      },
      {
        text: "f5 f5 ff 55 ff",
        type: "letter"
      },
      {
        text: "l9 l9 ll 99 ll",
        type: "letter"
      },
      {
        text: "d3 d3 dd 33",
        type: "letter"
      },
      {
        text: "0; 0; ;; 00 ;0 ;0;",
        type: "letter"
      },
      {
        text: "s2 s2 ss 22 ss",
        type: "letter"
      },
      {
        text: "j6 j6 jj 66",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 17,
    title: "New keys are slash and question mark",
    speed: 25,
    level: 505,
    accuracy: 85,
    instructions:
      "The key representing the slash and question mark is directly to the left of the right shift.  Use your right little finger to strike the slash.  Use two hands to type the question mark.  Use your left little finger to reach left shift  and your right little finger to reach the question mark. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "hjkl; qwert yuioj zxcvb nmop;",
        type: "letter"
      },
      {
        text: ";/ ;/; ;? V?",
        type: "letter"
      },
      {
        text: "The / is used in fractions.",
        type: "phrase"
      },
      {
        text: "The / also means divide.",
        type: "phrase"
      },
      {
        text: "one/half two/thirds one/tenth",
        type: "word"
      },
      {
        text: "What time do you go home?",
        type: "phrase"
      },
      {
        text: "Ten / two is five.",
        type: "phrase"
      },
      {
        text: "Where do you live?",
        type: "phrase"
      },
      {
        text: "Where do you go to school?",
        type: "phrase"
      },
      {
        text: "Do you play in the school band?",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 18,
    title: "The brackets and braces",
    speed: 25,
    level: 510,
    accuracy: 85,
    instructions:
      "Reach the [ with your right pinky finger. It is just right of P. ] is to the right of [. { and } are the shifts of [ and ]. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "[[ [[ ]] ]] [[]]",
        type: "letter"
      },
      {
        text: "{{ }} {{ }} {}",
        type: "letter"
      },
      {
        text: "{asd] jk[k [lkjh]",
        type: "letter"
      },
      {
        text: "hijk [qwer] [loijk]",
        type: "letter"
      },
      {
        text: "{1233} [7890] {5674}",
        type: "letter"
      },
      {
        text: "[yes] [no] [map] [verb]",
        type: "word"
      },
      {
        text: "[federal] {yellow} [crust]",
        type: "word"
      },
      {
        text: "[3 types] {glue} {grew]",
        type: "word"
      },
      {
        text: "Please add the numbers {4, 5} together.",
        type: "phrase"
      },
      {
        text: "Is she [Sue] going to work today?",
        type: "phrase"
      },
      {
        text: "Find the least common denominator for [10 and 12].",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 19,
    title: "Phrases",
    speed: 25,
    level: 514,
    accuracy: 85,
    instructions: "Type these simple phrases to sharpen your typing skills. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "yours truly",
        type: "phrase"
      },
      {
        text: "square meal",
        type: "phrase"
      },
      {
        text: "pardon me",
        type: "phrase"
      },
      {
        text: "good evening",
        type: "phrase"
      },
      {
        text: "until tomorrow",
        type: "phrase"
      },
      {
        text: "hot potato",
        type: "phrase"
      },
      {
        text: "knuckle down",
        type: "phrase"
      },
      {
        text: "the cutting edge",
        type: "phrase"
      },
      {
        text: "up to par",
        type: "phrase"
      },
      {
        text: "with regard to",
        type: "phrase"
      },
      {
        text: "tie the knot",
        type: "phrase"
      },
      {
        text: "on the ball",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 20,
    title: "More Phrases",
    speed: 25,
    level: 516,
    accuracy: 85,
    instructions: "Type these simple phrases to sharpen your typing skills. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "clear as a bell",
        type: "phrase"
      },
      {
        text: "hung the moon",
        type: "phrase"
      },
      {
        text: "kick the bucket",
        type: "phrase"
      },
      {
        text: "bells and whistles",
        type: "phrase"
      },
      {
        text: "can of worms",
        type: "phrase"
      },
      {
        text: "clean bill of health",
        type: "phrase"
      },
      {
        text: "the whole nine yards",
        type: "phrase"
      },
      {
        text: "with a grain of salt",
        type: "phrase"
      },
      {
        text: "lock, stock, and barrel",
        type: "phrase"
      },
      {
        text: "on the back burner",
        type: "phrase"
      },
      {
        text: "passed with flying colors",
        type: "phrase"
      },
      {
        text: "from stem to stern",
        type: "phrase"
      },
      {
        text: "the writing is on the wall",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 21,
    title: "Sentences",
    speed: 25,
    level: 518,
    accuracy: 85,
    instructions: "Type these simple sentences to sharpen your typing skill. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "Yesterday, James purchased a shiny, new automobile.",
        type: "phrase"
      },
      {
        text: "Please remember to close the window if it rains.",
        type: "phrase"
      },
      {
        text: "That old computer is malfunctioning horribly today.",
        type: "phrase"
      },
      {
        text: "Please read the book, pamphlet, and newsletter.",
        type: "phrase"
      },
      {
        text: "Mom said, \"Please clean the kitchen tomorrow.\"",
        type: "phrase"
      },
      {
        text: "The red and grey squirrels ate all of the tasty corn.",
        type: "phrase"
      },
      {
        text: "Linda likes to eat fish, slaw, and hot french fries.",
        type: "phrase"
      },
      {
        text: "\"Please don't make so much noise,\" my brother said.",
        type: "phrase"
      },
      {
        text: "Our dog has long hair, but our cat has short hair.",
        type: "phrase"
      },
      {
        text: "Our car refused to start on this very cold, snowy evening.",
        type: "phrase"
      },
      {
        text: "Will you please tell me what time it is?",
        type: "phrase"
      },
      {
        text: "The television reception was poor during the storm.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 22,
    title: "More Sentences",
    speed: 25,
    level: 519,
    accuracy: 85,
    instructions: "Type these sentences to sharpen your typing skill. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "Jenny found a beautiful gold coin in that old box.",
        type: "phrase"
      },
      {
        text: "Please come in; remove your coat; make yourself at home.",
        type: "phrase"
      },
      {
        text: "Are the numbers 2, 4, and 6 odd or even?",
        type: "phrase"
      },
      {
        text: "Most of the children played outside games after school.",
        type: "phrase"
      },
      {
        text: "In March, we plan to move into a new house.",
        type: "phrase"
      },
      {
        text: "Write the numbers 6, 8, and 2 on the board.",
        type: "phrase"
      },
      {
        text: "Marge brought a quilt and tanning lotion to the beach.",
        type: "phrase"
      },
      {
        text: "Shall we eat meat or vegetables for supper?",
        type: "phrase"
      },
      {
        text: "Who knew learning to type could be so much fun?",
        type: "phrase"
      },
      {
        text: "The ice cream, which was extremely tasty, was consumed quickly.",
        type: "phrase"
      },
      {
        text: "That old radio contains a broken speaker.",
        type: "phrase"
      },
      {
        text: "That large fireplace warmed the room completely.",
        type: "phrase"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 23,
    title: "Special Punctuation - ` and ~",
    speed: 25,
    level: 520,
    accuracy: 85,
    instructions:
      "` is directly above Tab. ~ is the shift of `.  Use your left pinky to strike these characters. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "`` `` ~~ ~~ ~`~",
        type: "letter"
      },
      {
        text: "~` ~` asdf` asdf~`",
        type: "letter"
      },
      {
        text: "as~ `` fd~ juk` kl~r ~cy `h`",
        type: "letter"
      },
      {
        text: "`` `` ~",
        type: "letter"
      },
      {
        text: "`~ ~.",
        type: "letter"
      },
      {
        text: "`~ `~?",
        type: "letter"
      },
      {
        text: "~` ~~.",
        type: "letter"
      },
      {
        text: "~` ~`.",
        type: "letter"
      },
      {
        text: "`` ~?",
        type: "letter"
      },
      {
        text: "~` ~`.",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 24,
    title: "Special Punctuation - exclamation and @",
    speed: 25,
    level: 530,
    accuracy: 85,
    instructions:
      "! and @ are up and a bit to the left of Q and W. They are the shifts of 1 and 2. Use your left little finger to type ! and your left ring finger to type @. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "1! 1! 2@ 2@ 1! !!",
        type: "letter"
      },
      {
        text: "2@ !@ !@ asd!@",
        type: "letter"
      },
      {
        text: "!! @! !! @@",
        type: "letter"
      },
      {
        text: "!! @@ @! @! @@",
        type: "letter"
      },
      {
        text: "@! !@ !! !!",
        type: "letter"
      },
      {
        text: "@",
        type: "letter"
      },
      {
        text: "!!",
        type: "letter"
      },
      {
        text: "@",
        type: "letter"
      },
      {
        text: "!",
        type: "letter"
      },
      {
        text: "@",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 25,
    title: "Special Punctuation - # and $",
    speed: 25,
    level: 540,
    accuracy: 85,
    instructions:
      "# and $ are the shifts of 3 and 4.  Use your left middle finger to type # and your left index finger to type $. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "## $$ ## $# #$$",
        type: "letter"
      },
      {
        text: "$$ #$ 34 #$ $34",
        type: "letter"
      },
      {
        text: "jk #$ kl ;3 4#",
        type: "letter"
      },
      {
        text: "## 34 #$ 34 $45",
        type: "letter"
      },
      {
        text: "#$ $$ #",
        type: "letter"
      },
      {
        text: "#37 8#",
        type: "letter"
      },
      {
        text: "$50 05$",
        type: "letter"
      },
      {
        text: "$70$",
        type: "letter"
      },
      {
        text: "#16#",
        type: "letter"
      },
      {
        text: "#78#",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 26,
    title: "Special Punctuation - % and ^",
    speed: 25,
    level: 550,
    accuracy: 85,
    instructions:
      "% and ^ are the shifts of 5 and 6.  Use your left index finger to type % and your right index finger to type ^. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "%% ^^ %^% ^%% ^^",
        type: "letter"
      },
      {
        text: "%% ^^ 55^ ^55 %6",
        type: "letter"
      },
      {
        text: "as% ^% ^%^",
        type: "letter"
      },
      {
        text: "%^^ %%^",
        type: "letter"
      },
      {
        text: "^.",
        type: "letter"
      },
      {
        text: "%100%",
        type: "letter"
      },
      {
        text: "^",
        type: "letter"
      },
      {
        text: "5%%",
        type: "letter"
      },
      {
        text: "^.",
        type: "letter"
      },
      {
        text: "^?^",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 27,
    title: "Special Punctuation - & and star",
    speed: 25,
    level: 560,
    accuracy: 85,
    instructions:
      "& and * are the shifts of 7 and 8.  Use your right index finger to type & and your right middle finger to type *. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "&*& **& *&& *& **&",
        type: "letter"
      },
      {
        text: "&* jkl &jkl ;*",
        type: "letter"
      },
      {
        text: "&*",
        type: "letter"
      },
      {
        text: "#45&",
        type: "letter"
      },
      {
        text: "6*5",
        type: "letter"
      },
      {
        text: "&",
        type: "letter"
      },
      {
        text: "&&",
        type: "letter"
      },
      {
        text: "9*7",
        type: "letter"
      },
      {
        text: "&",
        type: "letter"
      },
      {
        text: "10*4",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 28,
    title: "Special Punctuation - left parenthesis and right parenthesis",
    speed: 25,
    level: 570,
    accuracy: 85,
    instructions:
      "( and ) are the shifts of 9 and 0.  Use your right ring finger to type ( and your right little finger to type ). Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "(( )) (( )) () ()",
        type: "letter"
      },
      {
        text: "(0) ((9 )) (90)",
        type: "letter"
      },
      {
        text: "(67) (8*9) jkl;)",
        type: "letter"
      },      
      {
        text: "() () ()",
        type: "letter"
      },
      {
        text: "(.)",
        type: "letter"
      },
      {
        text: "(&)",
        type: "letter"
      },
      {
        text: "().",
        type: "letter"
      },
      {
        text: "().",
        type: "letter"
      },
      {
        text: "(.)",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 29,
    title: "Special Punctuation - - and _",
    speed: 25,
    level: 580,
    accuracy: 85,
    instructions:
      "- and _ are represented by the key directly to the right of 0.  Use your right little finger to type both characters.  _ is the shift of -. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "-- -_ --_ -__ __--",
        type: "letter"
      },
      {
        text: "-- _;_",
        type: "letter"
      },
      {
        text: "00- 0-0 -00",
        type: "letter"
      },
      {
        text: "--up - to--",
        type: "letter"
      },
      {
        text: "-two -to --19 45-",
        type: "letter"
      },
      {
        text: "-",
        type: "letter"
      },
      {
        text: "(_).",
        type: "letter"
      },
      {
        text: "_.",
        type: "letter"
      },
      {
        text: "-.",
        type: "letter"
      },
      {
        text: "-to-",
        type: "letter"
      },
      {
        text: "-y-",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 30,
    title: "Special Punctuation - = and +",
    speed: 25,
    level: 590,
    accuracy: 85,
    instructions:
      "= and + are represented by the key directly to the right of -.  Use your right little finger to type both characters.  + is the shift =. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "=+ =+ ==++",
        type: "letter"
      },
      {
        text: "jkl;+ jkl; = asdf+ fdsa+ =90+",
        type: "letter"
      },
      {
        text: "++ == ==",
        type: "letter"
      },
      {
        text: "== ++",
        type: "letter"
      },
      {
        text: "6 + 4=",
        type: "letter"
      },
      {
        text: "12+ 10=",
        type: "letter"
      },
      {
        text: "(9 +7 )=",
        type: "letter"
      },
      {
        text: "2 + 2= 4",
        type: "letter"
      },
      {
        text: "5 + 2 =",
        type: "letter"
      },
      {
        text: "11+ 2= 13",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 31,
    title: "Special Punctuation - backslash and bar",
    speed: 25,
    level: 595,
    accuracy: 85,
    instructions:
      "\\ and | are represented by the key directly above enter.  | is the shift of \\.  Use your right little finger to type both characters. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "asdf\\ asdf\\ asdf| |asdf",
        type: "letter"
      },
      {
        text: "qwer\\ \\ qwer|",
        type: "letter"
      },
      {
        text: "\\",
        type: "letter"
      },
      {
        text: "|| || \\ \\",
        type: "letter"
      },
      {
        text: "\\.",
        type: "letter"
      },
      {
        text: "|.",
        type: "letter"
      },
      {
        text: "|.",
        type: "letter"
      },
      {
        text: "\\?",
        type: "letter"
      },
      {
        text: "\\.",
        type: "letter"
      },
      {
        text: "\\|?",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 32,
    title: "Special Punctuation - less than and greater than",
    speed: 25,
    level: 596,
    accuracy: 85,
    instructions:
      "The < is the shift of ,.  The > is the shift of. .  Use your right middle finger to strike < and your right ring finger to strike >. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "> <<",
        type: "letter"
      },
      {
        text: "<> > <> <>",
        type: "letter"
      },
      {
        text: "<> <>",
        type: "letter"
      },
      {
        text: "<< <> <>",
        type: "letter"
      },
      {
        text: "5<7.",
        type: "letter"
      },
      {
        text: "<>?",
        type: "letter"
      },
      {
        text: "<>",
        type: "letter"
      },
      {
        text: "<>.",
        type: "letter"
      },
      {
        text: "<>",
        type: "letter"
      },
      {
        text: "<>.",
        type: "letter"
      },
      {
        text: "7<?",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 33,
    title: "Numeric Keypad - 4,5,6 - 1,2,3 - 7,8,9",
    speed: 25,
    level: 690,
    accuracy: 85,
    instructions:
      "This lesson covers the keys on the numeric keypad, which is on the right end of most computer keyboards.\n\nPosition the middle finger of your right hand on the 5, which is near the middle of the keypad and marked by a small projection.  Your right index finger should be resting on 4, and your right ring finger should be resting on 6.\n\nDirectly below 4, 5, and 6 are 1, 2, and 3, ordered numerically from left to right.  Use your right index finger to strike 1, your right middle finger to strike 2, and your right ring finger to strike 3.\n\nDirectly above 4, 5, and 6 are 7, 8, and 9, ordered numerically from left to right.  Use your right index finger to strike 7, your right middle finger to strike 8, and your right ring finger to strike 9.\n\nDon't forget to activate the numberic keypad by pressing numbers lock, which is located directly above 7. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "54 54 45 45 456",
        type: "letter"
      },
      {
        text: "4565 4565 5465",
        type: "letter"
      },
      {
        text: "21 21 12 12 123 123",
        type: "letter"
      },
      {
        text: "52 52 25 14 63 36 42 15 541 362",
        type: "letter"
      },
      {
        text: "87 87 78 78 789",
        type: "letter"
      },
      {
        text: "7898 7898 8978",
        type: "letter"
      },
      {
        text: "85 85 74 96 63",
        type: "letter"
      },
      {
        text: "53 57 84 69 28 37 71",
        type: "letter"
      },
      {
        text: "67 42 82 91 27 47",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 34,
    title: "Numeric Keypad - 0 and .",
    speed: 25,
    level: 700,
    accuracy: 85,
    instructions:
      "0 is the rectangular key directly below 1 and 2. . is directly below 3.  Use your right thumb to strike 0 and your right ring finger to strike .. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "50 50 05 05 60 60",
        type: "letter"
      },
      {
        text: "5. 5. 05 .5 .6 6.",
        type: "letter"
      },
      {
        text: "20 20 02 10",
        type: "letter"
      },
      {
        text: "2. 2. .3 .1 .2 3.",
        type: "letter"
      },
      {
        text: "80 80 08 90",
        type: "letter"
      },
      {
        text: "8. 8. .9 .8 7. .7",
        type: "letter"
      },
      {
        text: "506 .45 750 670",
        type: "letter"
      },
      {
        text: "8.4 009 570 824 28.6 74.6",
        type: "letter"
      },
      {
        text: "9.354 750.8 6509 56.57 75.60",
        type: "letter"
      },
      {
        text: "7820 56.09 740.9 1075",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },

  {
    id: 35,
    title: "Numeric Keypad - divide, multiply, minus",
    speed: 25,
    level: 720,
    accuracy: 85,
    instructions:
      "/ is located directly above 8.  Use your right middle finger to strike /.\n\n* is located directly above 9.  Use your right ring finger to strike *.\n\n- is located directly above +.  Use your right little finger to strike -. Use the Escape key (Esc) in the upper left corner of the keyboard to exit this lesson.",
    custom: false,
    drills: [
      {
        text: "5/ 5/ /5 5/5 6/5 6/66 5/65 4/6",
        type: "letter"
      },
      {
        text: "5* 5* 5* 6*4 6*5 54*6 4*56 4*64",
        type: "letter"
      },
      {
        text: "46-54 56-44 45-65 55-65",
        type: "letter"
      },
      {
        text: "9/77 98/97 87/8 98/7",
        type: "letter"
      },
      {
        text: "8* 8* 9*8 7*8 9*9",
        type: "letter"
      },
      {
        text: "8- 8- -8 -87 8-9",
        type: "letter"
      },
      {
        text: "2/ 2/ 2/1 3/2 3/1 2/3 13/ 1/12 3/1",
        type: "letter"
      },
      {
        text: "31*3 21*11 32*21 12*31",
        type: "letter"
      },
      {
        text: "49/58 93*24 21-3 164-1 24*14 24-67",
        type: "letter"
      }
    ],
    dictations: [
      {
        text: "do this",
        type: "do that",
      },
    ],
    enabled: true,
  },
];

