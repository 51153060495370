import React from 'react';
import GenericButton from './GenericButton';
import './LogModal.css';

export const LogModal = ({onSubmit, onCancel, onKeyDown}) => {
    var modal = document.getElementById("logModal");

    window.onclick = function(event){
        if(event.target === modal){
            modal.style.display = "none";
        }
    };

    return(
        <div id='logModal' className='modal' aria-modal="true" aria-label="Log Results modal">
            <div className='modal-content'>
                <div id="logModalHeader" className='modal-header' tabIndex={0}>
                    <h2>Log Results</h2>
                </div>
                <form onSubmit={onSubmit}>
                    <div className='modal-body'>
                        <div className='form-group'>
                            <label htmlFor='studentID'> Student ID (Optional): </label>
                            <input
	    			required={true}
                                defaultValue={0}
                                type="number"
                                id='studentID'
                                className='form-control'
	    			autoFocus={true}
                            />
                        </div>
                    
                    </div>
                    <div className='modal-footer' style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                        <GenericButton title="CANCEL" label="Cancel" type="reset" onClick={onCancel}/>
                        <GenericButton title="LOG RESULTS" label="Log results" type="submit" onClick={() => {}}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LogModal;
