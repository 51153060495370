import React, {Component} from "react";
import classes from "./About.module.css";

class About extends Component{
    constructor(props){
      super(props);
      this.aboutTitleRef = React.createRef();
    }

    componentDidMount(){
      if(this.aboutTitleRef.current !== null)
        setTimeout(()=>{this.aboutTitleRef.current.focus();},250);
    };

    render(){
      return(
        <div className={classes["container"]}>
          <div className={classes["content"]}>
            <h1 tabIndex={0} ref={this.aboutTitleRef}>About Typer Online</h1>
            <h3 tabIndex={0}>
              Typer Online is a cross-platform web application that uses universal
              design concepts and standard web technologies to deliver an accessible
              online typing tutorial for the QWERTY keyboard.
            </h3>
            <h4 tabIndex={0}>Company: American Printing House for the Blind</h4>
            <h4 tabIndex={0} >
              Submit feedback:{" "}
              <a href="mailto:technology@aph.org">technology@aph.org</a>
            </h4>
            <h4 tabIndex={0}>
              Your privacy is important. Please read the{" "}
              <a href="https://www.aph.org/privacy-policy/">APH privacy policy.</a>
            </h4>
            <h4 tabIndex={0} >Version: 1.0.8</h4>
          </div>
        </div>
      );
    };
}

export default About;
