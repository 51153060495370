import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Navigation.module.css";

const Navigation = () => {
  return (
    <nav className={classes.nav}>
      <ul>
        <li>
          <NavLink className={classes["links"]} to={"/lessons"}>
            <span className={classes["lessons"]}>Lessons</span>
          </NavLink>
        </li>
        <li>
          <a
            className={classes["a"]}
            href="https://tech.aph.org/ttweb/"
            target="_blank"
            rel="noreferrer"
          >
            <span className={classes["user_guide"]}>User Guide</span>
          </a>
        </li>
        <li>
          <NavLink className={classes["links"]} to={"/about"}>
            <span className={classes["about"]}>About</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
