import React, { Component } from 'react';
import { isOnAppleDevice, isOnAndroidDevice } from '../GlobalFuncs';
import { lessonsList } from "../../data/lessonsList.js";
import LessonIntro from './LessonIntro';
import LessonResults from './LessonResults';
import strings from '../strings';
import './LessonManager.css';

/*Manages all aspects of a lesson:
    - Starting a lesson
    - Handles user input
    - gathers elements needed for the result
    - Handles restarting a lesson.
    - Handles progressing to the next lesson
*/
//Test Comment
const BASE_SOUNDS_LOCATION = '../../assets/sounds/';
let previousFontSize = 0;

class LessonManager extends Component{
    constructor(props){
        super(props);

        this.initialLessonNum = this.props.params.lessonId - 1;
        this.initialLesson = lessonsList[this.initialLessonNum];
        this.userInputRef = new React.createRef();
        this.sfxPlayerRef = new React.createRef(); 
        this.lessonHeaderRef = new React.createRef();
        this.isPlaying = false;
        this.intervalID = setInterval(this.adjustTextAreaSize, 500);

        this.state = {
            currentLessonNum: this.initialLessonNum,
            lessonTitle: this.initialLesson.title,
            lessonInstruction: this.initialLesson.instructions,
            lessonDrills: this.initialLesson.drills,
            currentDrillIndex: 0,
            currentInnerDrillIndex: 0,
            currentInputIndex: 0,
            currentDrillText: '',
            totalInputs: 0,
            totalErrors: 0,
            isPaused: false,
            showLessonIntro: true,
            showLessonTest: false,
            showLessonResults: false,
            idleElapsedTime: 0,
            startTime: 0,
            totalElapsedTime: 0,
            isTimerRunning: false,
            soundQueue: [],
            soundIndex: 0,
            isSoundPlaying: false,
            isSelfVoiceOn: false,
            sfxSrc: BASE_SOUNDS_LOCATION + 'correct.wav',
            audioPlayer: new Audio(BASE_SOUNDS_LOCATION + 'correct.wav'),
            spaceIndex: -1,
            windowIdleTimerInterval: null
        };

        this.setSelfVoicingStatus();
        window.document.addEventListener('keydown', this.onHandleKeyDown);
        if(isOnAndroidDevice){
            window.document.addEventListener('keyup', this.onHandleKeyUp);
        }
        this.idleTimer = window.setInterval(this.onIncrementIdleTimer, 1000);
    }

    componentDidMount(){
        this.onFocusLessonTitleHeader();
    };

    componentWillUnmount(){
        this.onStopAllSounds();
        this.onStopSelfVoicing();
        clearInterval(this.intervalID);

        window.document.removeEventListener('keydown', this.onHandleKeyDown);
        if(isOnAndroidDevice){
            window.document.removeEventListener('keyup', this.onHandleKeyUp);
        }
        window.clearInterval(this.idleTimer);

        this.state.audioPlayer.removeEventListener('ended', this.onSoundEnd);
    };


    setSelfVoicingStatus = async () => {
        //Sets the self voicing switch to checked if it was previously set to checked.
        let selfVoicingStatus = await sessionStorage.getItem("useSpeech");

        if(selfVoicingStatus){
            this.setState({
                isSelfVoiceOn: selfVoicingStatus === 'true'
            });

            this.selfVoiceSpeakText(this.state.lessonInstruction);
        }
    };


    //Timer Methods:

    onIncrementIdleTimer = () => {
        if(this.state.showLessonTest && this.state.isTimerRunning){
            let currentIdleTime = this.state.idleElapsedTime;
            
            if(this.state.startTime !== 0)
                currentIdleTime++;

            if((currentIdleTime !== 0) && (currentIdleTime % 30 == 0))
                this.onRepeatSpeech();

            if(currentIdleTime !== this.state.idleElapsedTime)
                this.setState({idleElapsedTime: currentIdleTime});
        }
    };


    //Lesson Test Methods

    onStartLesson = () => {
        this.onStopSelfVoicing();//Stop any self voicing that is currently playing.

        this.setState({//Show the Lesson Test layout
            showLessonIntro: false,
            showLessonTest: true
        });

        this.onSetLessonDrillText(this.state.currentDrillIndex, this.state.currentInnerDrillIndex);
    };


    onSetLessonDrillText = async (drillNum, innerIndex) => {
         await this.onStopAllSounds();

        let drillCompleteText = this.state.lessonDrills[drillNum].text;
        let drillType = this.state.lessonDrills[drillNum].type;
        let displayedText = drillCompleteText;

        if(drillType !== 'phrase'){
            let splitText = drillCompleteText.split(' ');
            if(innerIndex < splitText.length)
                displayedText = splitText[innerIndex];
        }

        if(drillType !== 'letter'){
            if(this.state.isSelfVoiceOn)
                this.selfVoiceSpeakText(displayedText);
            else
                this.makeScreenReaderSpeak(displayedText);
        }
        else{
            await this.onPlayMultipleSounds(displayedText, 0);//Starting index is 0
        }

        this.setState({
            currentDrillIndex: drillNum,
            currentInnerDrillIndex: innerIndex,
            currentDrillText: displayedText
        });

        setTimeout(() => {
            this.onSetCaretPos(this.state.currentInputIndex);
        }, 20);
    };


    onHandleKeyDown = (e) => {
        let isKeyAllowed = (e.key !== "Tab") && (e.key !== "Shift") && (e.key !== "Control") && (e.key !== "Alt") && (e.key !== "NumLock") && (e.key !== "CapsLock") && (e.key !== "ArrowLeft") && (e.key !== "ArrowRight") && (e.key !== "ArrowUp") && (e.key !== "ArrowDown");
        if(this.state.showLessonTest && isKeyAllowed){
            if(e.key !== 'Escape'){
                this.setState({
                    idleElapsedTime: 0,//Input has been detected
                    startTime: this.state.startTime === 0? new Date(): this.state.startTime,
                    totalInputs: this.state.totalInputs + 1,
                    isTimerRunning: true
                });
                
                if(this.isInputCorrect(e.key)){
                    this.onInputCorrect();
                    if((this.state.currentInputIndex === this.state.currentDrillText.length) && (e.key === ' '))
                        this.onSingleDrillComplete();
                }
                else if(e.keyCode != 229){
                        this.onInputIncorrect();
                }
            }
            else{//Pause Lesson
                this.onHandlePause();
            }
        }

        if(e.key === 'Tab' && this.state.showLessonTest)
            this.onRepeatSpeech();
    };


    onHandleKeyUp = (e) => {
        if(isOnAndroidDevice()){
            this.onSetCaretPos(this.state.currentInputIndex);
            if(e.key == ' '){
                if(this.isInputCorrect(e.key)){
                    this.onInputCorrect();
                    if((this.state.currentInputIndex === this.state.currentDrillText.length))
                        this.onSingleDrillComplete();
                }
                else {
                    this.onInputIncorrect();
                }
            }
        }
    }


    isInputCorrect = (input) => {
        let bRes = false;
        if(this.state.currentInputIndex < this.state.currentDrillText.length)
            bRes = this.state.currentDrillText.charAt(this.state.currentInputIndex) === input;
        else
            bRes = ' ' === input;//Drill is ready to be submitted

        return bRes;
    };


    onInputCorrect = () => {
        if(this.state.currentInputIndex !== this.state.currentDrillText.length){
            this.setState({currentInputIndex: this.state.currentInputIndex + 1});
            this.onSetCaretPos(this.state.currentInputIndex);
        }
    };


    onInputIncorrect = () => {
        this.onStopAllSounds();
        this.onStopSelfVoicing();

        this.setState({totalErrors: this.state.totalErrors + 1});
        this.onPlaySingleSound(BASE_SOUNDS_LOCATION + 'incorrect.wav');
        this.onSpeakAfterIncorrectInput();
    };


    onSingleDrillComplete = () => {
        this.onPlaySingleSound(BASE_SOUNDS_LOCATION + 'correct.wav');
        this.onIncrementInnerDrill();
    };


    onIncrementDrill = () => {
        let newDrillIndex = this.state.currentDrillIndex + 1;

        if(newDrillIndex < this.state.lessonDrills.length){
            this.setState({
                currentDrillIndex: newDrillIndex,
                currentInnerDrillIndex: 0,
                currentInputIndex: 0
            });
            this.onSetLessonDrillText(newDrillIndex, 0);
        }
        else{
            this.onCompleteLesson();
        }
    };


    onIncrementInnerDrill = () => {
        let currentDrill = this.state.lessonDrills[this.state.currentDrillIndex];
        
        if(currentDrill.type !== "phrase"){
            let innerDrillCount = this.getInnerDrillCount(currentDrill);
            let newInnerDrillIndex = this.state.currentInnerDrillIndex + 1;

            if(newInnerDrillIndex < innerDrillCount){
                this.setState({
                    currentInnerDrillIndex: newInnerDrillIndex,
                    currentInputIndex: 0
                });
                this.onSetLessonDrillText(this.state.currentDrillIndex, newInnerDrillIndex);
            }
            else{//Gone through all inner drills
                this.onIncrementDrill();
            }
        }
        else{//Drills of type phrase only have one inner drill
            this.onIncrementDrill();
        }
    };


    getInnerDrillCount = (drill) => {
        let innerDrillSplit = drill.text.split(' ');
        return innerDrillSplit.length;
    };


    onCompleteLesson = () => {
        let endTime = new Date();
        let elapsedTime = endTime - this.state.startTime;

        this.setState({
            showLessonTest: false,
            showLessonResults: true,
            totalElapsedTime: this.state.totalElapsedTime + elapsedTime,
            isTimerRunning: false
        });
    };


    onSetCaretPos = (pos) => {
        if(this.userInputRef.current !== null)
            this.userInputRef.current.setSelectionRange(pos, pos);
    };


    focusInput = () => {
        if(this.userInputRef.current !== null){
            this.userInputRef.current.focus();
        }
    };


    adjustTextAreaSize = () => {
        if(this.userInputRef.current !== null){
            let fontSize = getComputedStyle(this.userInputRef.current).fontSize;

            if(previousFontSize !== fontSize){
                previousFontSize = fontSize;
                this.userInputRef.current.style.height = "auto";
                this.userInputRef.current.style.height = this.userInputRef.current.scrollHeight + 'px';
            }
        }
    };


    onFocusLessonTitleHeader = () => {
        if(this.lessonHeaderRef.current !== null)
            this.lessonHeaderRef.current.focus();
    };


    onFocusInput = () => {
        this.onSetCaretPos(this.state.currentInputIndex);
    };


    onBlurInput = () => {
        setTimeout(() => {//This needs to be in a timeout method otherwise it will not move the focus to the input area.
            if(this.state.showLessonTest)
                this.focusInput();
        });
    };


    onInput = (e) => {
        //I don't think this function is needed, but I will leave it here just incase.
    };


    onHandlePause = () => {
        this.onStopAllSounds();
        this.onStopSelfVoicing();

        let endTime = new Date();
        let elapsedTime = endTime - this.state.startTime;
        this.setState({
            isPaused: true,
            showLessonTest: false,
            showLessonResults: true,
            totalElapsedTime: this.state.totalElapsedTime + elapsedTime,
            isTimerRunning: false
        });
    };


    onResetLesson = () => {
        this.onFocusLessonTitleHeader();

        this.setState({
            totalErrors: 0,
            totalInputs: 0,
            currentDrillIndex: 0,
            currentInnerDrillIndex: 0,
            currentInputIndex: 0,
            totalElapsedTime: 0,
            startTime: 0,
            idleElapsedTime: 0,
            isPaused: false,
            isSoundPlaying: false,
            showLessonIntro: true,
            showLessonResults: false,
            showLessonTest: false,
            isTimerRunning: false,
            soundIndex: 0,
            soundQueue: []
        });
    };


    onResumeLesson = () => {
        this.setState({
            startTime: 0,
            idleElapsedTime: 0,
            showLessonTest: true,
            showLessonResults: false,
            isPaused: false,

        });

        this.onRepeatSpeech();
    };


    onNextLesson = () => {
        let nextLessonId = this.state.currentLessonNum + 1;
        
        this.setState({
            lessonDrills: lessonsList[nextLessonId].drills,
            lessonTitle: lessonsList[nextLessonId].title,
            lessonInstruction: lessonsList[nextLessonId].instructions,
            currentLessonNum: nextLessonId
        });

        if(this.state.isSelfVoiceOn){
            this.selfVoiceSpeakText(lessonsList[nextLessonId].instructions);
        }
        this.onResetLesson();
    };


    //Sound Methods

    onPlaySingleSound = async (soundFile) => {
        if(this.sfxPlayerRef.current !== null){
            this.sfxPlayerRef.current.src = soundFile;
            this.sfxPlayerRef.current.autoplay = true;
            await this.sfxPlayerRef.current.load();
        }
    };


    onPlayMultipleSounds = async (text, letterIndex) => {
        await this.onStopAllSounds();
        let textSlice = text.slice(letterIndex, text.length);//Gets the starting point for the letters to play
        let soundFiles = [];
        let indexOfSpace = textSlice.indexOf(' ');//This is -1 if no space is found.
        let endLoopIndex = indexOfSpace !== -1? indexOfSpace + 1: textSlice.length;
        let i = 0;

        while(i !== endLoopIndex){
            let soundFile = this.convertLetterToWavFile(textSlice.charAt(i));
            soundFiles.push(soundFile);
            i++;
        }

        if(soundFiles.length !== 0){
            this.setState({
                soundQueue: soundFiles,
                soundIndex: 1,
                spaceIndex: indexOfSpace !== -1? indexOfSpace + letterIndex: indexOfSpace
            }, async () => {
                await this.onPlayAudioFile(soundFiles[0]);
            });
        }
    };


    onPlayAudioFile = async  (soundFile) => {
        const {audioPlayer} = this.state

        if(audioPlayer.paused && !this.isPlaying){
            audioPlayer.src = soundFile;
            audioPlayer.onplaying = function() {this.isPlaying = true;};
            audioPlayer.onpause = function() {this.isPlaying = false;};

            return new Promise((resolve, reject) => {
                audioPlayer.addEventListener('ended', () => {
                    resolve();
                    this.onSoundEnd();
                });

                audioPlayer.load();
                audioPlayer.play()
                    .then(() => {
                        // Audio playback has started successfully
                    })
                    .catch((error) => {
                        console.error('Error playing sound: ', error);
                        resolve(); //Continue with the next sound even if there is an error
                        this.isPlaying = false;
                        this.onSoundEnd();
                    });
                
                this.setState({
                    isSoundPlaying: true
                });
            });
        }

        /*
            Adding an else statement here will allow you to handle what happens when we try to play a new sound and the 
            audio player is busy. Currently the only issue that is caused by this is that it will only speak the remaining characters
            after every other wrong button press. 
        */
    };


    convertLetterToWavFile = (letter) => {
        return BASE_SOUNDS_LOCATION + letter.charCodeAt(0) + '.wav';
    };


    onPlayNextInQueue = async (soundFile) => {
        this.setState({
            soundIndex: this.state.soundIndex + 1
        }, async () => {
            await this.onPlayAudioFile(soundFile);
        });
    };


    onStopAllSounds = async () => {
        return new Promise((resolve) => {
            const {audioPlayer} = this.state;
            if(!audioPlayer.paused){
                this.isPlaying = false;
                audioPlayer.pause();
            }

            this.setState({
                isSoundPlaying: false,
                soundQueue: [],
                soundIndex: 0,
                spaceIndex: -1
            }, () => {
                resolve(); // Resolve the Promise when the state is updated
            });
        });
    };


    onSoundEnd = async () => {
        this.isPlaying = false;
        let isDonePlayingSound = (this.state.soundIndex === this.state.soundQueue.length) || !this.state.isSoundPlaying;

        if(!isDonePlayingSound){//Play the next sound file
            const { audioPlayer } = this.state;
            if(audioPlayer.paused){
                await this.onPlayNextInQueue(this.state.soundQueue[this.state.soundIndex]);
            }
        }
        else{//Sound queue is done playing
            if(this.state.isSoundPlaying && (this.state.spaceIndex !== -1)){//Sounds may have ended prematurely.
                let remainingText = this.state.currentDrillText.slice(this.state.spaceIndex, this.state.currentDrillText.length);
                if(this.state.isSelfVoiceOn)
                    this.selfVoiceSpeakText(remainingText);
                else
                    setTimeout(() => {this.makeScreenReaderSpeak(remainingText);}, 100);
            }

            this.setState({
                soundQueue: [],
                isSoundPlaying: false,
                spaceIndex: -1,
                soundIndex: 0
            });
        }
    };


    //Speech Methods

    selfVoiceSpeakText = async (text) => {
        this.onStopSelfVoicing();//Stop any already running self-voicing.

        setTimeout(() => {
            if(this.state.isSelfVoiceOn){
                let speakText = this.addPunctuationToText(text);
                const { speak, supported} = this.props.speechSynthesis;
                if(supported)
                    speak({text: speakText});
            }
        }, 0);
    };


    onStopSelfVoicing = () => {
        const { speaking, cancel} = this.props.speechSynthesis;
        if(speaking)
            cancel();
    };


    addPunctuationToText = (text) => {
        const regEx = new RegExp(/[,\.\-_\\*&^%$#@!()+={}[\]`~?/'":;|<>]/g);
        const regExForInstruction = new RegExp(/[\-_\\*&^%$#@!()+={}[\]`~?/";:|<>]/g);
        let found = this.state.showLessonIntro? text.match(regExForInstruction): text.match(regEx);
        let res;

        if(found){
            let uniqueSymbols = [];

            for(let symbol of found){
                if(!uniqueSymbols.includes(symbol))
                    uniqueSymbols.push(symbol);
            }

            let currentText = text;
            let prevText = text;
            if(uniqueSymbols.includes(" ")){
                currentText = prevText.replaceAll(/\s/g, this.convertPunctuationToWord(' '));
                prevText = currentText;
            }

            for(let newSymbol of uniqueSymbols){
                if(newSymbol !== " "){
                    currentText = prevText.replaceAll(new RegExp(`[\\${newSymbol}]`, "g"), this.convertPunctuationToWord(newSymbol));
                    prevText = currentText;
                }
            }

            res = currentText;
        }
        else{
            res = text;
        }

        return res;
    };


    convertPunctuationToWord = (punctuation) => {
        return strings.PUNCTUATION_CONVERSION_TABLE[punctuation.charCodeAt(0)];
    };


    onRepeatSpeech = () => {
        let drillType = this.state.lessonDrills[this.state.currentDrillIndex].type;
        let isDrillWordOrPhrase = drillType === 'word' || drillType === 'phrase';

        if(this.state.currentInputIndex === 0){
            if(this.state.isSelfVoiceOn && isDrillWordOrPhrase){
                this.selfVoiceSpeakText(this.state.currentDrillText);
            }
            else if(!this.state.isSelfVoiceOn && isDrillWordOrPhrase){
                this.makeScreenReaderSpeak(this.state.currentDrillText);
            }
            else{
                this.onPlayMultipleSounds(this.state.currentDrillText, this.state.currentInputIndex);
            }
        }
        else{
            this.onPlayMultipleSounds(this.state.currentDrillText, this.state.currentInputIndex);
        }
    };


    onSpeakAfterIncorrectInput = () => {
        this.onPlayMultipleSounds(this.state.currentDrillText, this.state.currentInputIndex);
    };


    makeScreenReaderSpeak = async (text) => {
        let element = document.createElement("div");
        let id = "speak-" + Date.now();
        element.setAttribute("id", id);
        element.setAttribute("aria-live", "assertive");
        element.setAttribute("aria-atomic", true);
        element.classList.add("visually-hidden");
        let extrasText = this.addPunctuationToText(text);
        document.body.appendChild(element);
        let elementTimeout = isOnAppleDevice()? 0: 50;
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = extrasText;
        }, elementTimeout);

        window.setTimeout(function () {
            document.body.removeChild(document.getElementById(id));
        }, 425);
    };


    onClickSelfVoice = async () => {
        this.setState({isSelfVoiceOn: !this.state.isSelfVoiceOn});
        let selfVoiceValue = !this.state.isSelfVoiceOn? "true": "false";
        await sessionStorage.setItem("useSpeech", selfVoiceValue);
    };


    onSelfVoiceStatusChange = () => {
        if(!this.state.isSelfVoiceOn)
            this.selfVoiceSpeakText(this.state.lessonInstruction);
        else
            this.onStopSelfVoicing();
    };



    render(){
        return(
            <div className='container' style={{display: 'inline'}}>
                <h1 tabIndex={0} ref={this.lessonHeaderRef}>{this.state.lessonTitle}</h1>
                {
                    this.state.showLessonIntro? 
                        <LessonIntro
                            onClickSelfVoice={this.onClickSelfVoice.bind(this)}
                            onSelfVoiceChange={this.onSelfVoiceStatusChange.bind(this)}
                            onStartLesson={this.onStartLesson.bind(this)}
                            isSelfVoicing={this.state.isSelfVoiceOn}
                            LessonInstruction={this.state.lessonInstruction}
                        /> : null
                }
                {
                    this.state.showLessonTest?
                        <form action='' autoCorrect='off'>
                            <textarea 
                                ref={this.userInputRef} 
                                id="userInput"
                                rows={4} 
                                cols={50} 
                                autoFocus={true} 
                                autoComplete='off'
                                autoCapitalize='off'
                                autoCorrect='off'
                                spellCheck='false'
                                maxLength={this.state.currentDrillText.length}
                                value={this.state.currentDrillText}
                                onInput={this.onInput}
                                onBlur={this.onBlurInput}
                                onFocus={this.onFocusInput}
                                className="display-text" 
                                wrap="soft"
                            />
                            <h3 style={{border:"2px solid #3ed2b8", borderRadius: "9px"}} tabIndex={0}>{this.state.lessonInstruction}</h3>
                        </form>: null
                }
                {
                    this.state.showLessonResults?
                        <LessonResults 
                            onResumeLesson={this.onResumeLesson.bind(this)}
                            onNextLesson={this.onNextLesson.bind(this)}
                            onRestartLesson={this.onResetLesson.bind(this)}
                            elapsedTime={this.state.totalElapsedTime}
                            isPaused={this.state.isPaused}
                            LessonTitle={this.state.lessonTitle}
                            lastDrill={this.state.currentDrillText}
                            totalKeyInputs={this.state.totalInputs}
                            totalErrors={this.state.totalErrors}
                        /> : null
                }


                <audio ref={this.sfxPlayerRef} preload='auto'>
                    <source src={this.state.sfxSrc} type='audio/wav'/>
                </audio>
            </div>
        );
    };
}


export default LessonManager;