import React from "react";
import classNames from "classnames";
import "./MobileNavLinks.scss";
import { NavLink } from "react-router-dom";

const MobileNavLinks = ({ open, onClick }) => {
  const displayMobileNavLinks = classNames("mobile-nav-wrapper", {
    open: open,
  });

  return (
    <nav aria-label="mobile navigation" className={displayMobileNavLinks}>
      <NavLink className="mobile-link lessons" to="/lessons" onClick={onClick}>
        Lessons
      </NavLink>
      <a
        className="mobile-link user_guide"
        href="https://tech.aph.org/ttweb/"
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        User Guide
      </a>
      <NavLink className="mobile-link about" to="/about" onClick={onClick}>
        About
      </NavLink>
    </nav>
  );
};

export default MobileNavLinks;
