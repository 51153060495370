import React, { Component } from "react";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from 'react-cookie';

class Result extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props){
    super(props);

    this.headerRef = React.createRef();
    const { cookies } = props;

    this.state = {
      lastResult: cookies.get('lastResult') || null
    };
  }

  componentDidMount() {
      if(this.headerRef.current !== null){
        this.headerRef.current.focus();//Have screen reader users start on the results header
      }
  };

  render(){
    return (
      <div>
        <h1 tabIndex={0} ref={this.headerRef}>
            Recent Results
        </h1>
        {
          this.state.lastResult && <p tabIndex={0}><b>{this.state.lastResult.lessonTitle}</b></p>
        }
        {
          this.state.lastResult && <p tabIndex={0}><b>{this.state.lastResult.wordCount}</b></p>
        }
        {
          this.state.lastResult && <p tabIndex={0}><b>{this.state.lastResult.wordsPerMin}</b></p>
        }
        {
          this.state.lastResult && <p tabIndex={0}><b>{this.state.lastResult.errors}</b></p>
        }
        {
          this.state.lastResult && <p tabIndex={0}><b>{this.state.lastResult.accuracy}</b></p>
        }
    </div>
    );
  };
};

export default withCookies(Result);
