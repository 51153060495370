import React, { Component } from 'react';
import GenericButton from '../Buttons/GenericButton';
import strings from '../strings';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from 'react-cookie';
import { lessonsList } from "../../data/lessonsList.js";
import LogModal from '../Buttons/LogModal'; 
import './LessonResults.css';
import { saveAs } from 'file-saver';


/*This component is for showing the results when the user either finishes the lesson or pauses it. */
/*
    Props Passed In:
        onResumeLesson - (function)
        onRestartLesson - (function)
        onNextLesson - (function)
        elapsedTime - (long in milliseconds)
        totalKeyInputs - (int)
        totalErrors - (int)
        isPaused - (boolean)
        LessonTitle - (string)
        lastDrill - (string)
        LessonId - (int)
*/
class LessonResults extends Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);

	this.handleSave = this.handleSave.bind(this);
        this.resultsHeaderRef = React.createRef();
        this.totalWords = this.calculateTotalWords(this.props.totalKeyInputs, this.props.totalErrors);
        this.wordsPerMin = this.calculateWordsPerMin(this.props.totalKeyInputs, this.props.totalErrors, this.props.elapsedTime);
        this.lessonAccuracy = this.calculateAccuracy(this.props.totalKeyInputs, this.props.totalErrors);

        this.middleBtnText = !this.props.isPaused? strings.REPEAT_BTN_TXT : strings.RESTART_BTN_TXT;
        this.middleBtnLabel = !this.props.isPaused? strings.REPEAT_BTN_LABEL : strings.RESTART_BTN_LABEL;

        this.setLastResultCookie(this.totalWords, this.wordsPerMin, this.props.totalErrors, this.lessonAccuracy);
    }


    componentDidMount(){
        if(this.resultsHeaderRef.current !== null)
            this.resultsHeaderRef.current.focus();//Have screen reader users start on the results header
    };


    calculateTotalWords = (totalKeyInputs, totalErrors) => {
        return Math.floor((totalKeyInputs - totalErrors)/5);
    };


    calculateWordsPerMin = (totalKeyInputs, totalErrors, elapsedTime) => {
        let seconds = Math.floor(elapsedTime/1000);
        let min = seconds/60;
        return totalErrors !== 0? Math.floor((((totalKeyInputs)/5) -  (totalErrors/5))/min): Math.floor(((totalKeyInputs)/5)/min);
    };


    calculateAccuracy = (totalKeyInputs, totalErrors) => {
        return totalKeyInputs !== 0? Math.floor((((totalKeyInputs * 1.0) - totalErrors) / totalKeyInputs) * 100): 0;
    };


    setLastResultCookie = (totalWords, wordsPerMin, errors, lessonAccuracy) => {
        const { cookies } = this.props;
        let cookieConsent = cookies.get(strings.COOKIE_CONSENT_KEY) || false;

        if(cookieConsent){
            let cookieNewValue = {"lessonTitle": this.props.LessonTitle, "wordCount": totalWords + strings.TOTAL_WORDS, "wordsPerMin": wordsPerMin + strings.WPM, "errors": errors + strings.TOTAL_ERRORS, "accuracy": lessonAccuracy + strings.LESSON_ACCURACY};
            cookies.set(strings.LAST_RESULTS_KEY, JSON.stringify(cookieNewValue), { path: '/', maxAge: 31560000000});//Max age is one year in seconds
        }
    };

    showLogModal = () => {
	let modal = document.getElementById("logModal");
	if(modal){
	    modal.style.display = "block";
	}
	let elem = document.getElementById("logModalHeader");
	if(elem){
	    elem.focus();
	}
    };

    onCancelLog = () => {
	let modal = document.getElementById("logModal");
	if(modal){
	    modal.style.display = "none";
	}
    };

    onSubmitLog = async (event) => {
	let studentID = event.target.studentID.value;
        let date = new Date();
	let dateString = date.toLocaleString();
	let msg = `${dateString}\n${studentID ? `Student ID: ${studentID}\n` : ''}Lesson: ${this.props.LessonTitle}\nLesson Completed: ${!this.props.isPaused? "yes": "no"}\nLast Attempted Drill: ${this.props.lastDrill}\n${this.totalWords + strings.TOTAL_WORDS}\n${this.wordsPerMin + strings.WPM}\n${this.props.totalErrors + strings.TOTAL_ERRORS}\n${this.lessonAccuracy + strings.LESSON_ACCURACY}`;
	await this.handleSave(msg);
	this.onCancelLog();
    }

    handleSave = async (msg) => {
        const blob = new Blob([msg], { type: 'text/plain;charset=utf-8' });
	const date = new Date();
	const dateString = date.toISOString().split('T')[0];
	const filename = `results_${dateString}.txt`;
        saveAs(blob, filename);
        this.props.onRestartLesson();
    };

    render(){
        return(
            <div>
                <div style={{border: "1px solid #3ed2b8", borderRadius: "7px"}}>
                    <div>
                        <h2 tabIndex={0} ref={this.resultsHeaderRef}>{strings.RESULTS_HEADER}</h2>
                    </div>

                    <p tabIndex={0} ><b>{this.totalWords + strings.TOTAL_WORDS}</b></p>
                    <p tabIndex={0} ><b>{this.wordsPerMin + strings.WPM}</b></p>
                    <p tabIndex={0} ><b>{this.props.totalErrors + strings.TOTAL_ERRORS}</b></p>
                    <p tabIndex={0} ><b>{this.lessonAccuracy + strings.LESSON_ACCURACY}</b></p>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                    {
                        this.props.isPaused?
                        <GenericButton title={strings.RESUME_BTN_TXT} label={strings.RESUME_BTN_LABEL} onClick={this.props.onResumeLesson}/>:
                        <GenericButton title={strings.NEXT_BTN_TXT} label={strings.NEXT_BTN_LABEL} onClick={this.props.onNextLesson} disabled={this.props.LessonId !== (lessonsList.length)}/>
                    }

                    <GenericButton title={this.middleBtnText} label={this.middleBtnLabel} onClick={this.props.onRestartLesson}/>
                    <GenericButton title={strings.LOG_RESULTS_BTN_TXT} label={strings.LOG_RESULTS_BTN_TXT} onClick={this.showLogModal}/>
                </div>
		<LogModal onSubmit={this.onSubmitLog} onCancel={this.onCancelLog}/>
            </div>
        );
    };
}


export default withCookies(LessonResults);
