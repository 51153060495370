import React, { Component } from "react";
import "./Lessons.css";
import { NavLink} from "react-router-dom";
import { lessonsList } from "../../data/lessonsList.js";
import Result from "./Result";


class Lessons extends Component{
  constructor(props){
    super(props);
    this.lessonHeaderRef = React.createRef();
  }

  componentDidMount(){
    if(this.lessonHeaderRef.current !== null && !this.props.showResult)
      setTimeout(()=>{this.lessonHeaderRef.current.focus();},250);
  }

  render(){
    return (
      <div className="container">
        {this.props.showResult ? (
          <div className="container-result">
            <Result />
          </div>
        ) : (
        ""
        )}
        <div className="container-lessons" tabIndex={0}>
          <div>
            <h1 tabIndex={0} ref={this.lessonHeaderRef}>Lessons</h1>
          </div>
          {lessonsList.map((lessonItem) => ( 
            <div className="card" aria-label={lessonItem.title} key={lessonItem.id}>
              <NavLink 
                  to={`/lessons/${lessonItem.id}`}
                  key={lessonItem.id}>
                  <span>{lessonItem.title}</span>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    );
  };
};

export default Lessons;
