import React from "react";
import classes from "./Welcome.module.css";

const Welcome = () => {
  return (
    <div className={classes["container"]} aria-describedby="Typer Online">
      <div className={classes["content"]}>
        <h1>Typer Online</h1>
      </div>
    </div>
  );
};

export default Welcome;
