export default {
    ANDROID_DISCLAIMER: ' On Android devices, a Bluetooth keyboard is needed to complete lessons, and the predictive text setting on the device needs to be turned off.',
    START_BTN_ACCESSIBILITY_LABEL: 'Start Lesson',
    START_BTN_TITLE: 'Start',
    SELF_VOICING_TOGGLE_LABEL: 'Toggle Self-Voicing:',
    SELF_VOICING_TOGGLE_ACCESSIBILITY_LABEL: 'toggle self-voicing ',
    STATUS_ON: 'ON',
    STATUS_OFF: 'OFF',
    RESULTS_HEADER: 'Results:',
    RESUME_BTN_TXT: 'RESUME',
    RESUME_BTN_LABEL: 'resume lesson',
    REPEAT_BTN_TXT: 'REPEAT',
    REPEAT_BTN_LABEL: 'repeat lesson',
    RESTART_BTN_TXT: 'RESTART',
    RESTART_BTN_LABEL: 'restart lesson',
    NEXT_BTN_TXT: 'NEXT',
    NEXT_BTN_LABEL: 'next lesson',
    LOG_RESULTS_BTN_TXT: 'SAVE RESULTS TO FILE',
    COPY_RESULTS_BTN_TXT: 'COPY RESULTS',
    TOTAL_WORDS: ' words',
    TOTAL_ERRORS: ' errors',
    WPM: ' words per minute',
    LESSON_ACCURACY: '% accuracy',
    EMAIL_SUBJECT: 'Typer Online: Results for lesson ',
    COOKIE_CONSENT_KEY: 'TyperCookieConsent',
    LAST_RESULTS_KEY: 'lastResult',

    PUNCTUATION_CONVERSION_TABLE: {
       32: ' space ',
       33: ' exclamation mark ',
       34: ' quotation mark ',
       35: ' number ',
       36: ' dollar sign ',
       37: ' percent sign ',
       38: ' ampersand ',
       39: ' apostrophe ',
       40: ' left parenthesis ',
       41: ' right parenthesis ',
       42: ' star ',
       43: ' plus ',
       44: ' comma ',
       45: ' dash ',
       46: ' period ',
       47: ' slash ',
       58: ' colon ',
       59: ' semicolon ',
       60: ' less than ',
       61: ' equals ',
       62: ' greater than ',
       63: ' question mark ',
       64: ' at ',
       91: ' left bracket ',
       92: ' backslash ',
       93: ' right bracket ',
       94: ' caret ',
       95: ' underscore ',
       96: ' grave accent ',
       123: ' left brace ',
       124: ' bar ',
       125: ' right brace ',
       126: ' tilde '
    }
};
