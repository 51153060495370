import React, { useState } from "react";

import Navigation from "../Navigation/Navigation";
import classes from "./MainHeader.module.css";
import tt_logo from "../../assets/images/tt_logo.jpg";
import classNames from "classnames";
import MobileNavLinks from "../MobileNavigation/MobileNavLinks";

import "../../styles.scss";

import { NavLink } from "react-router-dom";
import Buttons from "../Buttons/Buttons";

const MainHeader = (props) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const active = classNames("mobile-menu", {
    open: mobileNavOpen,
  });

  return (
    <>
      <header className="navigation-wrapper">
        <div className="navigation-header">
          <div className={classes["container"]}>
            <div className={classes["column"]}>
              <NavLink to="/">
                <img
                  className={classes["img"]}
                  alt="Typer Logo"
                  src={tt_logo}
                ></img>
              </NavLink>
            </div>
            <div>
              <NavLink to="/" className={classes["text"]}>
                Typer Online
              </NavLink>
            </div>
          </div>

          {props.showMobileNavMenu ? (
            <span className="navigation-links">
              <button
                aria-label="Toggle Mobile Menu Button"
                className={active}
                onClick={() => {
                  setMobileNavOpen((mobileNavOpen) => !mobileNavOpen);
                }}
              >
                <div className="bar-one" />
                <div className="bar-two" />
                <div className="bar-three" />
              </button>
            </span>
          ) : (
            <>
              <Buttons {...props} />
              <Navigation />
            </>
          )}
        </div>
      </header>
      {props.showMobileNavMenu ? ( <Buttons {...props}/> ): ("")}
      <MobileNavLinks
        onClick={() => {
          setMobileNavOpen((mobileNavOpen) => !mobileNavOpen);
        }}
        open={mobileNavOpen}
      />
    </>
  );
};

export default MainHeader;
